<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <!-- Dates of Alleged Incidents -->
                <b-col md="6">
                    <ValidationProvider name="dates_of_alleged_incidents" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('dates_of_alleged_incidents')">
                            <multi-select-date
                                v-model="request_data.dates_of_alleged_incidents"
                                :state="errors.length === 0"
                            />
                            <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- Name of Persons -->
                <b-col md="6">
                    <ValidationProvider name="name_of_persons" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('name_of_persons')">
                            <b-form-input
                                type="number"
                                v-model="request_data.name_of_persons"
                                :state="errors.length === 0"
                            />
                            <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- Witness Persons -->
                <b-col md="6">
                    <ValidationProvider name="witness_persons" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('witness_persons')">
                            <b-form-input
                                type="number"
                                v-model="request_data.witness_persons"
                                :state="errors.length === 0"
                            />
                            <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- Incident Location -->
                <b-col md="6">
                    <ValidationProvider name="incident_location" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('incident_location')">
                            <b-form-input
                                type="text"
                                v-model="request_data.incident_location"
                                :state="errors.length === 0"
                            />
                            <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- Incident Details -->
                <b-col md="6">
                    <ValidationProvider name="incident_details" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('incident_details')">
                            <b-form-input
                                type="text"
                                v-model="request_data.incident_details"
                                :state="errors.length === 0"
                            />
                            <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- Complaint Person -->
                <b-col md="6">
                    <ValidationProvider name="complaint_person" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('complaint_person')">
                            <b-form-input
                                type="text"
                                v-model="request_data.complaint_person"
                                :state="errors.length === 0"
                            />
                            <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- File Upload -->
                <b-col md="12">
                    <b-form-group :label="$t('file')">
                        <div class="d-flex custom-file-upload">
                            <b-form-file
                                v-model="request_data.file"
                                :placeholder="$t('select_file')"
                                :drop-placeholder="$t('drop_file')"
                                ref="fileInput"
                                multiple
                            ></b-form-file>
                            <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">
                                {{ $t('browse') }}
                            </b-button>
                        </div>
                        <div class="alert alert-info mt-2">{{ $t('warning_file2') }}</div>
                    </b-form-group>
                </b-col>

                <!-- Save Button -->
                <b-col cols="12" md="2">
                    <b-form-group class="mb-0">
                        <b-button variant="primary" block @click="save">
                            {{ $t('save').toUpperCase() }}
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import StudentRequestService from '@/services/StudentRequestService';
import MultiSelectDate from '@/components/interactive-fields/MultiSelectDate.vue';
import moment from 'moment';

export default {
    components: { MultiSelectDate },
    props: {
        data: {
            type: Object
        }
    },
    data() {
        return {
            request_data: {
                dates_of_alleged_incidents: [],
                name_of_persons: null,
                witness_persons: null,
                incident_location: null,
                incident_details: null,
                complaint_person: null,
                file: []
            }
        };
    },
    computed: {
        getReservationToken() {
            return this.$store.getters['roomReservationSystem/getReservationToken'];
        }
    },
    methods: {
        async save() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                const formData = new FormData();
                if (this.request_data.dates_of_alleged_incidents.length) {
                    this.request_data.dates_of_alleged_incidents.forEach((date, index) => {
                        formData.append(`request_data[dates_of_alleged_incidents][${index}]`, moment(date).format('MM/DD/YYYY'));
                    });
                }
                formData.append('request_data[name_of_persons]', this.request_data.name_of_persons);
                formData.append('request_data[witness_persons]', this.request_data.witness_persons);
                formData.append('request_data[incident_location]', this.request_data.incident_location);
                formData.append('request_data[incident_details]', this.request_data.incident_details);
                formData.append('request_data[complaint_person]', this.request_data.complaint_person);
                formData.append('request_type', 'initial_complaint');
                formData.append('housing_reservation_id', this.data.id);

                if (this.request_data.file.length) {
                    this.request_data.file.forEach((file, index) => {
                        formData.append(`request_data[file][${index}]`, file);
                    });
                }

                try {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${this.getReservationToken}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    await StudentRequestService.studentRequest(formData, config);
                    this.$toast.success(this.$t('api.request_success'));
                    this.$router.push('/housing/request/list');
                } catch (error) {
                    this.showErrors(error, this.$refs.formModalValidate);
                }
            }
        }
    }
};
</script>

