<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <!-- Service Animal Checkbox -->
<!--                <b-col cols="6">-->
<!--                    <ValidationProvider name="service_animal" rules="required" v-slot="{ errors }">-->
<!--                        <b-form-group>-->
<!--                            <b-form-checkbox-->
<!--                                id="checkbox-1"-->
<!--                                v-model="request_data.service_animal"-->
<!--                                name="checkbox-1"-->
<!--                                value="accepted"-->
<!--                                unchecked-value="not_accepted"-->
<!--                                :state="errors.length === 0"-->
<!--                            >-->
<!--                                {{ $t('service_animal') }}-->
<!--                            </b-form-checkbox>-->
<!--                            <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>-->
<!--                        </b-form-group>-->
<!--                    </ValidationProvider>-->
<!--                </b-col>-->

                <!-- File Upload -->
                <b-col cols="12" class="mt-3">
                    <b-form-group :label="$t('file')">
                        <div class="d-flex custom-file-upload">
                            <b-form-file
                                v-model="request_data.file"
                                :placeholder="$t('select_file')"
                                :drop-placeholder="$t('drop_file')"
                                ref="fileInput"
                                multiple
                            ></b-form-file>
                            <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">
                                {{ $t('browse') }}
                            </b-button>
                        </div>
                        <div class="alert alert-info mt-2">{{ $t('warning_file2') }}</div>
                    </b-form-group>
                </b-col>

                <!-- Save Button -->
                <b-col cols="12" md="2">
                    <b-form-group class="mb-0">
                        <label class="d-none d-md-block">&nbsp;</label>
                        <b-button variant="primary" block @click="save">
                            {{ $t('save').toUpperCase() }}
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import StudentRequestService from '@/services/StudentRequestService';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            request_data: {
                service_animal: 'not_accepted', // Varsayılan değer
                file: [],
            },
            formLoading: false,
        };
    },
    computed: {
        getReservationToken() {
            return this.$store.getters['roomReservationSystem/getReservationToken'];
        },
    },
    methods: {
        async save() {
            this.formLoading = true;
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${this.getReservationToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                };

                const formData = new FormData();
                formData.append('request_type', 'service_animal');
                formData.append('housing_reservation_id', this.data.id);
                formData.append('request_data[service_animal]', this.request_data.service_animal);

                if (this.request_data.file && this.request_data.file.length > 0) {
                    this.request_data.file.forEach((file, index) => {
                        formData.append(`request_data[file][${index}]`, file);
                    });
                }

                try {
                    const response = await StudentRequestService.studentRequest(formData, config);
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$router.push('/housing/request/list');
                } catch (error) {
                    this.showErrors(error, this.$refs.formModalValidate);
                } finally {
                    this.formLoading = false;
                }
            } else {
                this.formLoading = false;
            }
        },
    },
};
</script>
