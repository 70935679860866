<template>
    <div>

            <div class="border rounded p-5 mb-3">
                <ParameterSelectbox code="housing_request_types" v-model="requestType" :public="true" />
            </div>
            <div class="col-12" v-if="requestType">
                <h6>
                    <span v-if="requestType && requestType.text">
                        {{ requestType.text }}
                    </span>
                </h6>
                <div class="border rounded p-5">
                    <component v-if="type" :is="type" :data="reservationData" />
                </div>
            </div>
    </div>
</template>

<script>
    // Pages
    import bedHoldRequest from './pages/student/bedHoldRequest/Index'
    import serviceAnimal from './pages/student/serviceAnimal/Index'
    import roomChangeRequest from './pages/student/roomChangeRequest'
    import workOrderRequest from './pages/student/workOrderRequest/Index'
    import initialComplaint from './pages/student/initialComplaint/Index'
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";


    export default {
        props:{
          reservationData:{
              type: Object,
          }
        },
        components: {
            bedHoldRequest,
            serviceAnimal,
            roomChangeRequest,
            workOrderRequest,
            initialComplaint,
            ParameterSelectbox
        },
        data() {
            return {
                requestType: null
            }
        },
        computed: {
            type(){
                if (this.requestType){
                    return  this.requestType?.replaceAll('_','-')
                }
                return this.requestType

            }

        }
    }
</script>
