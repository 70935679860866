<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <!-- Request Details -->
                <b-col md="6">
                    <ValidationProvider name="request_details" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('request_details')">
                            <b-form-input
                                v-model="request_data.request_details"
                                :state="errors.length === 0"
                            />
                            <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- Location -->
                <b-col md="6">
                    <ValidationProvider name="location" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('location')">
                            <b-form-input
                                v-model="request_data.location"
                                :state="errors.length === 0"
                            />
                            <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- Category -->
                <b-col md="6">
                    <ValidationProvider name="category" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('category')">
                            <parameter-selectbox
                                code="housing_work_order_request_categories"
                                :multiple="true"
                                :public="true"
                                v-model="request_data.category"
                                :state="errors.length === 0"
                            />
                            <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>

                    <div v-show="showOther">
                        <ValidationProvider name="other_text" :rules="showOther?'required':''" v-slot="{ errors }">
                            <b-form-group :label="$t('other')">
                                <b-form-input
                                    v-model="request_data.other_text"
                                    :state="errors.length === 0"
                                />
                                <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </b-col>

                <!-- Completion Date -->
                <b-col md="6">
                    <ValidationProvider name="completion_date" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('completion_date')">
                            <select-date
                                v-model="request_data.completion_date"
                                :date-format="'MM/DD/YYYY'"
                                :state="errors.length === 0"
                            />
                            <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- Urgency -->
                <b-col md="6">
                    <ValidationProvider name="urgency" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('urgency')">
                            <parameter-selectbox
                                code="housing_work_order_request_urgency"
                                :public="true"
                                v-model="request_data.urgency"
                                :state="errors.length === 0"
                            />
<!--                            <b-form-input-->
<!--                                v-model="request_data.urgency"-->
<!--                                :state="errors.length === 0"-->
<!--                            />-->
                            <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- File Upload -->
                <b-col cols="12" class="mt-3">
                    <b-form-group :label="$t('file')">
                        <div class="d-flex custom-file-upload">
                            <b-form-file
                                v-model="request_data.file"
                                :placeholder="$t('select_file')"
                                :drop-placeholder="$t('drop_file')"
                                ref="fileInput"
                                multiple
                            ></b-form-file>
                            <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">
                                {{ $t('browse') }}
                            </b-button>
                        </div>
                        <div class="alert alert-info mt-2">{{ $t('warning_file2') }}</div>
                    </b-form-group>
                </b-col>

                <!-- Save Button -->
                <b-col cols="12" md="2">
                    <b-form-group class="mb-0">
                        <b-button variant="primary" block @click="save">
                            {{ $t('save').toUpperCase() }}
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components

    // Services
    import StudentRequestService from '@/services/StudentRequestService'

    // Other

    export default {
        props: {
            data:{
                type:Object
            },
        },
        data() {
            return {
                selected: null,
                request_data: {
                    request_details: null,
                    location: null,
                    category: null,
                    completion_date: null,
                    urgency: null,
                },
                showOther:false
            }
        },
        computed:{
            getReservationToken(){
                return this.$store.getters['roomReservationSystem/getReservationToken']
            }
        },
        watch:{
          'request_data.category'(val){
              this.showOther = val.includes('other');
          }
        },
        methods: {
            async save() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${this.getReservationToken}`,
                            'Content-Type': 'multipart/form-data'
                        },

                    };


                    const formData = new FormData();
                    formData.append('request_data[request_details]', this.request_data.request_details);
                    formData.append('request_data[location]', this.request_data.location);
                    formData.append('request_data[completion_date]', this.request_data.completion_date);
                    formData.append('request_data[urgency]', this.request_data.urgency);
                    if (this.request_data.other!=null){
                        formData.append('request_data[other_text]', this.request_data.other_text);

                    }
                    formData.append('request_data[urgency]', this.request_data.urgency);

                    if (this.request_data.category && this.request_data.category.length > 0) {
                        for (let i = 0; i < this.request_data.category.length; i++) {
                            formData.append(`request_data[category][${i}]`, this.request_data.category[i]);
                        }
                    }

                    if (this.request_data.file && this.request_data.file.length > 0) {
                        for (let i = 0; i < this.request_data.file.length; i++) {
                            formData.append(`request_data[file][${i}]`, this.request_data.file[i]);
                        }
                    }

                    formData.append('request_type', 'work_order_request');
                    formData.append('housing_reservation_id', this.data.id);

                    StudentRequestService.studentRequest(formData,config)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.$router.push('/housing/request/list');
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                }
            }
        }
    }
</script>
