import Api from '@/services/Index';


const studentRequest = async (data,config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/housing/requests', data,config);
}

export default {
    studentRequest
}
