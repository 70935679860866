<template>
    <div>
        <ValidationObserver ref="formModalValidatess">
            <b-row>
                <!-- Reason Input -->
                <b-col cols="12">
                    <ValidationProvider name="reason" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('reason')">
                            <b-form-input
                                type="text"
                                v-model="request_data.reason"
                                :state="errors.length === 0"
                            />
                            <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- File Upload Input -->
                <b-col md="12">
                    <ValidationProvider name="file" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('file')">
                            <div class="d-flex custom-file-upload">
                                <b-form-file
                                    v-model="request_data.file"
                                    :state="errors.length === 0"
                                    :placeholder="$t('select_file')"
                                    :drop-placeholder="$t('drop_file')"
                                    ref="fileInput"
                                    multiple
                                ></b-form-file>
                                <b-button
                                    variant="outline-secondary"
                                    @click="$refs.fileInput.$el.childNodes[0].click();"
                                >
                                    {{ $t('browse') }}
                                </b-button>
                            </div>
                            <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                            <div class="alert alert-info mt-2">{{ $t('warning_file2') }}</div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- Save Button -->
                <b-col cols="12" md="2">
                    <b-form-group class="mb-0">
                        <label class="d-none d-md-block">&nbsp;</label>
                        <b-button variant="primary" block @click="save">
                            {{ $t('save').toUpperCase() }}
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>

    // Services
    import StudentRequestService from '@/services/StudentRequestService'

    // Other

    export default {
        props: {
            data:{
                type:Object
            },
        },
        data() {
            return {
                selected: null,
                request_data: {
                    housing_bed_id:this.data.housing_bed_id,
                    reason: null,
                    file:[]
                }
            }
        },
        computed:{
            getReservationToken(){
                return this.$store.getters['roomReservationSystem/getReservationToken']
            }
        },

        methods: {
            async save() {
                const isValid = await this.$refs.formModalValidatess.validate();
                if (isValid) {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${this.getReservationToken}`,
                            'Content-Type': 'multipart/form-data'
                        },
                    };
                    const formData = new FormData();

                    formData.append('request_data[housing_bed_id]', this.request_data.housing_bed_id);
                    formData.append('request_data[reason]', this.request_data.reason);
                    formData.append('housing_reservation_id',this.data.id);
                    formData.append('request_type', 'room_change_request');

                    if (this.request_data.file && this.request_data.file.length > 0) {
                        for (let i = 0; i < this.request_data.file.length; i++) {
                            formData.append(`request_data[file][${i}]`, this.request_data.file[i]);
                        }
                    }
                    StudentRequestService.studentRequest(formData,config)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.$router.push('/housing/request/list');
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                }
            }
        }
    }
</script>
